.cta {
  background: $neutral-700;
  color: $neutral-200;

  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-musicvideo,
  &-aftermovie {
    background: $neutral-200;
    color: $neutral-700;
  }

  &-musicvideo {
    img {
      filter: saturate(0.7);
    }
  }

  &-event {
    img {
      filter: grayscale(0.7)
    }
  }

  &-imagefilm {
    img {
      filter: saturate(0.5);
    }
  }

  &-aftermovie {
    img {
      filter: saturate(0.8);
    }
  }

  &-musicvideo,
  &-imagefilm,
  &-aftermovie,
  &-event {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include desktop() {
      flex-direction: row;
    }

    &-image {
      position: relative;
      height: 40vh;

      @include tablet() {
        height: 60vh;
      }

      @include desktop() {
        width: 50%;
        min-height: 100vh;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 60vh;
      padding: 40px;
      margin: auto 0;

      @include tablet() {
        min-height: 40vh;
      }

      @include desktop() {
        width: 60%;
        height: 100%;
      }

      &-title {
        width: 75%;
        font-size: $large-title-font-size;
        line-height: $large-title-line-height;
        font-weight: $large-title-font-weight-emphasized;
        text-transform: uppercase;
      }

      &-main {
        width: 75%;
        margin: 20px 0;
        font-size: $title2-font-size;
        line-height: 1.5;
        font-weight: $title2-font-weight;

        @include tablet() {
          margin: 40px 0;
        }
      }

      button {
        font-size: $title3-font-size;
        line-height: $title3-line-height;
        font-weight: $title3-font-weight-emphasized;
        width: 160px;
        height: 40px;
        cursor: pointer;
        border: 1px solid $neutral-700;

        &.light {
          background: $neutral-200;
          border: 1px solid $neutral-700;

          &:hover {
            background: $neutral-700;
            color: $neutral-200;
          }
        }

        &.dark {
          background: $neutral-700;
          border: 1px solid $neutral-200;
          color: $neutral-200;

          &:hover {
            background: $neutral-200;
            color: $neutral-700;
          }
        }
      }
    }
  }

  &-social {
    color: $neutral-100;
    justify-content: center;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 100px 0 40px 0;
    height: calc(50vh - 30px);

    &-icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      cursor: pointer;

      @include tablet() {
        height: 28px;
        width: 28px;
      }

      @include tablet() {
        height: 36px;
        width: 36px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:hover {
        opacity: 1;
      }

      &-facebook {
        background: #3b5998;
      }

      &-instagram {
        background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }

      &-linkedin {
        background: #0077b5;
      }

      &-xing {
        background: #026466;
      }

      &-youtube {
        background: #ff0000;
      }

      svg {
        height: 60%;
        width: 60% !important;

        * {
            fill: white;
        }
      }
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    height: calc(50vh - 30px);

    &-text {
      font-size: $title1-font-size;
      line-height: $large-title-line-height;
      font-weight: $title1-font-weight;

      padding-bottom: 32px;
      text-align: center;
      max-width: 70vw;
    }

    &-mail {
      color: $neutral-100;
      height: 36px;
      width: 140px;
      background: $accent-color;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      cursor: pointer;

      @include tablet() {
        height: 48px;
        width: 200px;
      }

      &:hover {
        opacity: 1;
      }

      svg {
        height: 60%;
        width: 60% !important;
      }
    }
  }
}
