.intro {
    background: $neutral-200;
    color: $neutral-700;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    width: 100vw;
    font-size: $title3-font-size;
    line-height: $title3-line-height;
    font-weight: $title3-font-weight;

    @include tablet() {
        height: 72px;
        padding: 0 60px;
    }

    @include desktop() {
        height: 80px;
        padding: 0 120px;
        font-size: $title2-font-size;
        line-height: $title2-line-height;
        font-weight: $title2-font-weight;
    }

    &-image {
        height: 100%;
        padding: 12px 0;

        img {
            border-radius: 50%;
            height: 100%;
        }
    }

    &-name {
        margin: 0 24px;
    }

    &-text {
        font-weight: $title3-font-weight-emphasized;
        display: flex;
        text-transform: uppercase;
        margin-right: auto;

        p {
            margin: 12px 0;
        }
    }
}