.footer {
    background: $neutral-700;
    color: $neutral-200;

    height: 60px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;


    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: $title3-font-size;
    line-height: $title3-line-height;
    font-weight: $title3-font-weight;

    @include tablet() {
        padding: 0 60px;
    }

    @include desktop() {
        padding: 0 120px;
    }

    &-right {
        display: flex;

        span {

            &:first-child,
            &:last-child {
                cursor: pointer;

                &:hover {
                    color: $neutral-100;
                    font-weight: $title3-font-weight-emphasized;
                }
            }
        }
    }
}