@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');

@import "colors";
@import "fonts";
@import "responsive";

@import "intro";
@import "references";
@import "showcasefilmmaking";
@import "cta";
@import "footer";
@import "impressum";
@import "datenschutz";


html {
  height: 100%;
  box-sizing: border-box;
  font-size: $body-font-size;
  line-height: $body-line-height;
  font-weight: $body-font-weight;
  scroll-behavior: smooth;

  @include tablet() {
    font-size: $body-font-size-tablet;
    line-height: $body-line-height-tablet;
  }

  @include desktop() {
    font-size: $body-font-size-desktop;
    line-height: $body-line-height-desktop;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: 0;
}

body {
  @include fontSmoothing;
  margin: 0;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-font-color;
  height: 100%;
}