.impressum {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $neutral-200;
    color: $neutral-700;
    padding: 40px;
    overflow: scroll;
}

.close-button {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 10;
    cursor: pointer;

    svg {
        path {
            fill: $neutral-700;

            &:hover {
                fill: $neutral-400;
            }
        }
    }
}