$neutral-800: #000;
$neutral-700: #18191A;
$neutral-600: #303030;
$neutral-500: #4F4F4F;
$neutral-400: #767676;
$neutral-300: #A0A0A0;
$neutral-200: #E4E6EB;
$neutral-100: #fff;

$accent-color: #5050fe;
$accent-color-lighter: #5050fee2;
$accent-color-transparent: #5050fe50;

$success-color: #00a900;
$error-color: #ff4f4f;

$pride-colors: conic-gradient(from 180deg at 50% 50%, #5C62F5 0deg, #52A7F5 52.5deg, rgba(62, 194, 83, 0.792683) 99.37deg, #EEDD42 153.75deg, #FDBC15 211.88deg, #F96342 270deg, #FF0099 318.75deg, #5C62F5 360deg);

$main-font-color: $neutral-200;