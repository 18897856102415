$breakpoint-tablet: 700px;
$breakpoint-desktop: 1140px;


@mixin maxWidth($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}