.showcase-filmmaking {
    background: $neutral-500;
    color: $neutral-700;

    padding: 0 60px;

    height: calc(100vh - 60px);
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    @include tablet() {
        height: calc(100vh - 72px)
    }

    @include desktop() {
        height: calc(100vh - 80px)
    }

    video {
        height: 100%;
        filter: grayscale(0.8);
        opacity: 0.8;
        object-fit: cover;
    }
}