// $large-title-font-size: 26px;
$large-title-font-size: 2rem;
$large-title-line-height: 32px;
$large-title-font-weight: 400;
$large-title-font-weight-emphasized: 800;

// $title1-font-size: 22px;
$title1-font-size: 1.7rem;
$title1-line-height: 26px;
$title1-font-weight: 400;
$title1-font-weight-emphasized: 800;

// $title2-font-size: 17px;
$title2-font-size: 1.31rem;
$title2-line-height: 22px;
$title2-font-weight: 400;
$title2-font-weight-emphasized: 800;

// $title3-font-size: 15px;
$title3-font-size: 1.15rem;
$title3-line-height: 20px;
$title3-font-weight: 400;
$title3-font-weight-emphasized: 600;

// $headline-font-size: 13px;
$headline-font-size: 1rem;
$headline-line-height: 16px;
$headline-font-weight: 800;
$headline-font-weight-emphasized: 900;

// $subheadline-font-size: 11px;
$subheadline-font-size: 0.85;
$subheadline-line-height: 14px;
$subheadline-font-weight: 400;
$subheadline-font-weight-emphasized: 600;

$body-font-size: 12px;
$body-line-height: 15px;
$body-font-weight: 400;
$body-font-weight-emphasized: 600;

$body-font-size-tablet: 14px;
$body-line-height-tablet: 18px;

$body-font-size-desktop: 16px;
$body-line-height-desktop: 22px;


@mixin fontSmoothing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility !important;
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
}