.references {
    background: $neutral-700;
    color: $neutral-200;

    padding: 20px;
    margin-left: auto;
    margin-right: auto;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet() {
        padding: 40px;
    }

    @include desktop() {
        padding: 60px;
    }

    &-title {
        font-size: $large-title-font-size;
        line-height: $large-title-line-height;
        font-weight: $large-title-font-weight-emphasized;

        text-align: center;

        padding-bottom: 60px;
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
        justify-content: center;

        @include tablet() {
            grid-template-columns: repeat(auto-fit, minmax(120px, 280px));
        }

        @include desktop() {
            grid-template-columns: repeat(auto-fit, minmax(120px, 280px));
        }

        &-item {
            background-color: $neutral-100;
            height: 80px;
            margin-bottom: 24px;
            padding: 12px 20px;
            border-radius: 12px;
            overflow: hidden;
            cursor: pointer;
            transition: transform 0.5s;

            &-sm {
                padding: 12px 32px;
            }

            @include tablet() {
                height: 160px;
                padding: 12px 24px;

                &-sm {
                    padding: 12px 40px;
                }
            }

            &:hover {
                transform: scale(1.05);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}